"use client";
import Link from "next/link";
import {
  useAccount,
  useBalance,
  useBlockNumber,
  useChainId,
  useEnsAvatar,
  useEnsName,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
import {
  Button,
  Avatar,
  Flex,
  DropdownMenu,
  IconButton,
} from "@radix-ui/themes";
import { useAccountModal, useConnectModal } from "@rainbow-me/rainbowkit";
import { useIsMounted } from "usehooks-ts";
import { useMemo } from "react";
import {
  FROGE_TOKEN,
  NATIVES,
  OMNICAT_TOKENS,
} from "@/app/features/swap/constants";
import { formatUnits, isAddress } from "viem";
import { formatBigIntCompact, shortenEthAddress } from "@/common/utils";
import DownIcon from "@/common/icons/DownIcon";
import { useAppDispatch } from "@/app/hooks";
import { setOutputToken, switchNetworkState } from "@/app/features/swap";
import { useTheme } from "next-themes";
import { MoonIcon, SunIcon } from "@radix-ui/react-icons";
import clsx from "clsx";
import { useCountDown } from "@/common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";

export default function Header() {
  const { openConnectModal } = useConnectModal();
  const { openAccountModal } = useAccountModal();
  const { address } = useAccount();
  const { data } = useEnsName({ address, chainId: 1 });
  const isMounted = useIsMounted()();
  const chainId = useChainId();
  const dispatch = useAppDispatch();
  const { chains, chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork({
    onSuccess: () => dispatch(switchNetworkState()),
  });

  const native = useMemo(() => NATIVES[chainId || 1], [chainId]);
  const { data: balance } = useBalance({
    address,
    watch: true,
    chainId,
    cacheTime: 0,
  });
  const { data: frogeBalance } = useBalance({
    address,
    watch: true,
    chainId: 1,
    token: FROGE_TOKEN.address,
  });

  const { data: blockNumber } = useBlockNumber();
  const { theme, setTheme } = useTheme();
  const timeLeft = useCountDown(new Date("May 23, 2024 14:30:00 UTC"));

  return (
    <>
      <div>
        {chainId === 1 && timeLeft && (
          <div className="hidden items-center justify-center gap-6 bg-gray-2 px-4 py-2 text-sm md:flex">
            <Avatar src="/sanshu.png" fallback="P" radius="full" />
            <div className="flex gap-2 font-medium">
              <span>🐸</span> FROGEX partnership is here! 10x rewards on all
              FROGEX swaps until May 29, 2024.
              <span>🐸</span>
            </div>
            <div className="flex gap-2">
              <div className="flex flex-col">
                <span className="text-xs text-grayA-11">Days</span>
                <span>{timeLeft.days}</span>
              </div>
              <div className="flex flex-col">
                <span className="text-xs text-grayA-11">Hours</span>
                <span>{timeLeft.hours}</span>
              </div>
              <div className="flex flex-col">
                <span className="text-xs text-grayA-11">Minutes</span>
                <span>{timeLeft.minutes}</span>
              </div>
              <div className="flex flex-col">
                <span className="text-xs text-grayA-11">Seconds</span>
                <span>{timeLeft.seconds}</span>
              </div>
            </div>
            <Button
              size="1"
              onClick={() =>
                dispatch(
                  setOutputToken({
                    address: "0x5fA54fdDF1870C344DbFaBb37dFab8700Ec0Def1",
                    decimals: 18,
                    symbol: "FROGEX",
                    name: "FrogeX",
                    logoURI: "/frogex.png",
                    chainId: 1,
                  }),
                )
              }
            >
              Trade Now
            </Button>
          </div>
        )}
        <header className="z-10 flex w-full items-center justify-between px-4 py-4 text-sm">
          <div className="flex items-center gap-10">
            <div className="font-mono">
              <Link
                className="pointer-events-none flex place-items-center gap-3 lg:pointer-events-auto"
                href="/"
              >
                <img
                  src="/images/logo.png"
                  alt="Froge Logo"
                  width={40}
                  height={40}
                />
                <span className="hidden sm:flex">FrogeSwap</span>
              </Link>
            </div>
            <div className="hidden items-center gap-4 lg:flex">
              <Link href="https://froge.vip" target="_blank">
                <span className="flex items-center gap-2 hover:text-accent-9">
                  Froge VIP <FontAwesomeIcon size="xs" icon={faExternalLink} />
                </span>
              </Link>
              <Link href="https://docs.frogeswap.vip" target="_blank">
                <span className="flex items-center gap-2 hover:text-accent-9">
                  Froge Docs <FontAwesomeIcon size="xs" icon={faExternalLink} />
                </span>
              </Link>
              <Link href="https://dune.com/frogelabs/frogeswap" target="_blank">
                <span className="flex items-center gap-2 hover:text-accent-9">
                  Froge Dashboard{" "}
                  <FontAwesomeIcon size="xs" icon={faExternalLink} />
                </span>
              </Link>
            </div>
          </div>
          {isMounted && (
            <Flex align="center" gap="4">
              {address && (
                <>
                  <Flex align="center" gap="2">
                    <Avatar fallback="N" src={FROGE_TOKEN.logoURI} size="1" />
                    {`${formatBigIntCompact(
                      formatUnits(
                        frogeBalance?.value || 0n,
                        FROGE_TOKEN.decimals,
                      ),
                    )} ${FROGE_TOKEN.symbol}`}
                  </Flex>
                  <DropdownMenu.Root>
                    <DropdownMenu.Trigger className="outline-none">
                      <Button variant="soft">
                        <Flex align="center" gap="2" asChild>
                          <span>
                            <Avatar
                              fallback="N"
                              src={native.logoURI}
                              size="1"
                            />
                            <span className="hidden sm:flex">
                              {`${formatBigIntCompact(
                                formatUnits(
                                  balance?.value || 0n,
                                  native.decimals,
                                ),
                              )} ${native.symbol}`}
                            </span>
                            <DownIcon />
                          </span>
                        </Flex>
                      </Button>
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content>
                      {chains.map((chain) =>
                        chain.id === chainId ? null : (
                          <DropdownMenu.Item
                            key={chain.id}
                            onClick={() => switchNetwork?.(chain.id)}
                          >
                            <Flex gap="4">
                              <Avatar
                                fallback="N"
                                src={NATIVES[chain.id].logoURI}
                                size="1"
                              />
                              {chain.name}
                            </Flex>
                          </DropdownMenu.Item>
                        ),
                      )}
                    </DropdownMenu.Content>
                  </DropdownMenu.Root>
                </>
              )}
              {!address && (
                <Button className="hidden sm:flex" onClick={openConnectModal}>
                  Connect
                </Button>
              )}
              {address && (
                <Button className="hidden sm:flex" onClick={openAccountModal}>
                  {shortenEthAddress(data || address)}
                </Button>
              )}
              <IconButton
                variant="soft"
                onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
              >
                {theme === "dark" ? <MoonIcon /> : <SunIcon />}
              </IconButton>
            </Flex>
          )}
        </header>
      </div>
      {isMounted && (
        <footer className="fixed bottom-0 z-[1000] flex w-full items-center justify-end gap-2 bg-panel px-4 py-3 sm:bg-transparent">
          <div className="flex items-center gap-4 text-sm  lg:hidden">
            <Link
              href="https://froge.vip"
              target="_blank"
              className="hidden sm:flex"
            >
              <span className="flex items-center gap-2 hover:text-accent-9">
                Froge <FontAwesomeIcon size="xs" icon={faExternalLink} />
              </span>
            </Link>
            <Link href="https://docs.frogeswap.vip" target="_blank">
              <span className="flex items-center gap-2 hover:text-accent-9">
                Docs <FontAwesomeIcon size="xs" icon={faExternalLink} />
              </span>
            </Link>
            <Link
              href="https://dune.com/frogelabs/frogeswap"
              target="_blank"
              className="hidden sm:flex"
            >
              <span className="flex items-center gap-2 hover:text-accent-9">
                Dashboard <FontAwesomeIcon size="xs" icon={faExternalLink} />
              </span>
            </Link>
          </div>
          <IconButton asChild variant="ghost">
            <Link href="https://twitter.com/FrogeVIP/" target="_blank">
              <FontAwesomeIcon icon={faXTwitter} />
            </Link>
          </IconButton>
          {Boolean(blockNumber) && chain && (
            <a
              href={`${chain.blockExplorers?.default.url}/block/${blockNumber}`}
              target="_blank"
              className="flex cursor-pointer items-center gap-2 text-sm before:block before:h-1.5 before:w-1.5 before:rounded-full before:bg-green-9 hover:text-green-9"
            >
              {blockNumber?.toString()}
            </a>
          )}
          <div className="flex sm:hidden">
            {!address && (
              <Button size="3" onClick={openConnectModal}>
                Connect
              </Button>
            )}
            {address && (
              <Button onClick={openAccountModal} size="3">
                {shortenEthAddress(data || address)}
              </Button>
            )}
          </div>
        </footer>
      )}
    </>
  );
}
